import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Footer() {
 
  return (
    <Container fluid className="footer">
     
    </Container>
  );
}

export default Footer;
